import React from 'react';
import { ModalContextType, ModalType, useModalContext } from 'src/hooks/useModal';
import { ClosePositionModalContent } from './ClosePositionModalContent';
import { PositionModalWrapper } from '../FlowCommons/PositionModalWrapper';
import { ScrollModal } from '../../primitives/ScrollModal';

export const ClosePositionModal = () => {
  const { type, args, close } = useModalContext() as ModalContextType<{
    positionAddress: string;
  }>;

  return (
    <ScrollModal open={type === ModalType.ClosePosition} setOpen={close}>
      <PositionModalWrapper
        positionAddress={args.positionAddress}
        title="Close position"
        modernStyle={true}
      >
        {(params) => <ClosePositionModalContent {...params} />}
      </PositionModalWrapper>
    </ScrollModal>
  );
};
