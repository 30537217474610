import React, { useEffect, useState } from 'react';

import { useModalContext } from '../../../hooks/useModal';
import { ScrollModalContent, ScrollModalFooter } from '../../primitives/ScrollModal';
import { PositionModalWrapperProps } from '../FlowCommons/PositionModalWrapper';
import { DetailsNumberLine, TxModalDetails } from '../FlowCommons/TxModalDetails';
import { TxSuccessView } from '../FlowCommons/Success';
import { TxPositionDetails } from '../FlowCommons/TxPositionDetails';
import { SLIPPAGE_WARNING } from '../constants';
import { ClosePositionActions } from './ClosePositionActions';
import { AlertBanner } from '../../../ui-kit/AlertBanner';
import { DEFAULT_SLIPPAGE_OPTIONS } from '../../../ui-config/networksConfig';
import { BigNumber } from 'bignumber.js';
import { SelectSlippage } from '../../../components-yldr/SelectSlippage';

export const ClosePositionModalContent = React.memo(
  ({ enrichedPosition, positionAddress, isWrongNetwork }: PositionModalWrapperProps) => {
    const [slippage, setSlippage] = React.useState(DEFAULT_SLIPPAGE_OPTIONS[0]);
    const [amount, setAmount] = useState<string>('');
    const { gasLimit, txError, mainTxState, setTxError } = useModalContext();

    const closePositionActionsProps = {
      marketId: enrichedPosition.marketId,
      slippage: BigNumber(slippage).multipliedBy(100).toNumber(),
      isWrongNetwork,
      positionAddress,
    };

    useEffect(() => {
      setAmount(enrichedPosition?.marketReferenceCurrencyValue.toFixed(2));
    }, []);

    if (mainTxState.success) {
      return (
        <ScrollModalContent>
          <TxSuccessView
            action="Close position"
            customText={`
              You have closed
              ${enrichedPosition?.token0.symbol} / ${enrichedPosition?.token1.symbol}
              position for $${amount}
            `}
          />
        </ScrollModalContent>
      );
    }

    return (
      <>
        <ScrollModalContent>
          <TxPositionDetails token0={enrichedPosition.token0} token1={enrichedPosition.token1} />
          <TxModalDetails
            description={SLIPPAGE_WARNING}
            gasLimit={gasLimit}
            skipLoad={true}
            bottomSlot={(
              <SelectSlippage defaultOptions={DEFAULT_SLIPPAGE_OPTIONS} value={slippage} onChange={setSlippage} />
            )}
          >
            <DetailsNumberLine
              description="Total liquidity to withdraw"
              value={enrichedPosition.marketReferenceCurrencyValue}
              numberPrefix="$"
            />
            <DetailsNumberLine
              description="Fees earned"
              value={
                enrichedPosition.token0.marketReferenceCurrencyFee + enrichedPosition.token1.marketReferenceCurrencyFee
              }
              numberPrefix="$"
            />
          </TxModalDetails>

          {txError && (
            <AlertBanner variant="error" txError={txError} onClose={() => setTxError(undefined)} />
          )}
        </ScrollModalContent>

        <ScrollModalFooter>
          <ClosePositionActions {...closePositionActionsProps} />
        </ScrollModalFooter>
      </>
    );
  }
);
